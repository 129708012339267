html, body {
  height: 100%;
  overflow: hidden;
}

body {
  min-height: 100%;
  font-family: 'Karla', sans-serif;
}

@media (max-width: 900px) {
  img {
    max-width: 100%;
    height: auto;
  }
}


#root {
  height: 100%;
}

